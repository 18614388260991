@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.css-1fdsijx-ValueContainer {
  height: 53px;
}

.css-1nmdiq5-menu {
  z-index: 999 !important;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle {
  color: #a7a19e !important;
  /* font-family: Noto Sans Bengali UI; */
  text-algin: center;
  font-size: 17px;
}

.css-heg063-MuiTabs-flexContainer {
  background: #a7a19e0d;
  border-radius: 10px;
}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: linear-gradient(
    216.56deg,
    var(--color-primary-100) 5.32%,
    var(--color-primary-200) 94.32%
  );
  color: white !important;
  border-radius: 10px;
  border: none !important;
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

@layer base {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
    --color-primary-100: #2d3192;
    --color-primary-200: #ec1f24;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;
    color: white;
  }

  body {
    font-family: var(--Poppins-font);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  h1,
  .h1 {
    @apply text-4xl lg:text-5xl leading-normal;
  }
  h2,
  .h2 {
    @apply text-3xl lg:text-4xl leading-normal;
  }
  h3,
  .h3 {
    @apply text-2xl lg:text-3xl leading-normal;
  }
  h4,
  .h4 {
    @apply text-xl lg:text-2xl leading-normal;
  }
  p {
    @apply text-sm lg:text-base leading-normal;
  }
}

@layer utilities {
  .bg-gradient-primary {
    background: linear-gradient(
      216.56deg,
      var(--color-primary-100) 5.32%,
      var(--color-primary-200) 94.32%
    );
    color: black;
  }

  .bg-gradient-light {
    background: radial-gradient(
      130.35% 183.32% at 0.64% 5.28%,
      rgba(236, 31, 36, 0.35) 0%,
      rgba(45, 49, 146, 0.38) 85.07%
    );
  }

  .border-gradient-primary {
    position: relative;
  }
  .border-gradient-primary::before {
    content: "";
    position: absolute;
    transition-duration: 200ms;
    inset: 0;
    padding: 2px;
    background: linear-gradient(
      216.56deg,
      var(--color-primary-100) 5.32%,
      var(--color-primary-200) 94.32%
    );
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  .border-gradient-rounded::before {
    border-radius: 8px;
  }

  .strokeRed path {
    stroke: #ec1f24;
  }

  .boxShadow {
    box-shadow: 0px 8px 8px -4px #10182805;
  }
}
